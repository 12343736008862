import React, { useState } from 'react';
import axios from 'axios';
import MapComponent from "./MapComponent";

export default function Contact() {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('http://localhost:8000/api/send-email', formData)
      .then(response => {
        console.log('Email sent successfully:', response.data);
        // Reset the form after successful submission
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: '',
        });
      })
      .catch(error => {
        console.error('Error sending email:', error);
      });
  };
  return (
    <main>
      <div className="slider-area">
        <div className="slider-height2 slider-bg4 hero-overly d-flex align-items-center">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-5 col-lg-6 col-md-6">
                <div className="hero-caption hero-caption2">
                  <h2 style={{ textAlign: "start" }}>Contact Us</h2>
                </div>
              </div>
            </div>
          </div>a
        </div>
      </div>

      <div className="container services-area1 section-padding">
        <div className="row">
          <h2 class="contact-title text-left">Find us on</h2>
        </div>
        <MapComponent /><br /><br />
        <div class="row">
          <div class="col-4">
            <h2 class="contact-title text-left">Get in Touch</h2>
          </div>
          <div class="col-lg-8">
            <form className="form-contact contact_form" onSubmit={handleSubmit}>
              <div className="col-sm-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <textarea
                    className="form-control w-100"
                    placeholder="Enter Message"
                    name="message"
                    cols="10"
                    rows="10"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <div className="form-group mt-3">
                <button type="submit" className="button button-contactForm boxed-btn">
                  Send
                </button>
              </div>
            </form>
          </div>
          <div class="col-lg-3 offset-lg-1">
            <div class="media contact-info">
              <span class="contact-info__icon"><i class="ti-home"></i></span>
              <div class="media-body">
                <h3>Uzi Kisiwani Zanzibar</h3>
                <p>P.O.Box </p>
              </div>
            </div>
            <div class="media contact-info">
              <span class="contact-info__icon"><i class="ti-tablet"></i></span>
              <div class="media-body">
                <h3>+255 778 314 679</h3>
                <p>Mon to Fri 9am to 6pm</p>
              </div>
            </div>
            <div class="media contact-info">
              <span class="contact-info__icon"><i class="ti-email"></i></span>
              <div class="media-body">
                <h3>
                  info@nuwarisha.org
                </h3>
                <p>Send us your query anytime!</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </main>
  );
}