import React, { useState, useEffect } from 'react';
import axios from 'axios';

const StaffImage = ({ staffId }) => {
  const [staffImage, setStaffImage] = useState(null);

  useEffect(() => {
    const fetchStaffImage = async () => {
      try {
        const response = await axios.get(`https://backend.nuwarisha.org/api/staff/${staffId}/image`);
        setStaffImage(`data:image/jpeg;base64,${response.data.image}`);
      } catch (error) {
        console.error('Error fetching staff image:', error);
      }
    };

    fetchStaffImage();
  }, [staffId]);

  return (
    <div>
      {staffImage ? (
        <img src={staffImage} style={{width:'220px',height:'200px'}} class="float-left"alt="Staff" />
      ) : (
        <p>no image!</p>
      )}
    </div>
  );
};

export default StaffImage;
