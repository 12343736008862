import React, { useState, useEffect } from 'react';
import axios from 'axios';

import StaffImage from './StaffImage';

const Staff = () => {
  const [rows, setRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    // Fetch data from the Laravel API endpoint
    axios.get('https://backend.nuwarisha.org/api/staff')
      .then(response => {
        setRows(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); // Empty dependency array ensures the effect runs once after initial render

  const filteredRows = rows.filter(row =>
    row.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.position.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.address.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-12">
          {/* Search Input and Add Staff Button */}
          <div className="d-flex justify-content-between mb-4">
            <input
              type="text"
              className="form-control"
              placeholder="Search member"
              style={{width:'250px'}}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

          </div>

          {/* Staff List */}
          <div className="row">
            {filteredRows.map(row => (
              <div key={row.id} className="col-lg-4 col-md-6 col-sm-6">
                <div className="properties pb-30">
                  <div className="properties-card">
                    <div className="properties-img">
                      <StaffImage staffId={row.id} />
                      <div className="single-skill">
                        <div className="bar-progress">
                          <div id={`bar${row.id}`} className="barfiller">
                            <div className="tipWrap">
                              <span className="tip"></span>
                            </div>
                            <span className="fill" data-percentage="65"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrap-wrapper">
                      <div className="properties-caption">
                        <h3><a href="#">{row.name}</a></h3>
                        <p>Position: {row.position}</p>
                        <p>Address: {row.address}</p>
                        <p>Phone: {row.phone}</p>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

        </div>
      </div>
    </div>
  );
};

export default Staff;
