import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const slidesContent = [
  {
    id: 1,
    title: 'Welcome To Nuwarisha Mayatima',
    description: 'With Love and Hope, We Bring Smiles to Orphaned Children and People with Disabilities',
    buttonText: 'Donate Now',
    buttonLink: 'contact',
    backgroundImage: 'img/hero/h1_hero3.jpg',
  },
  // Add more slides as needed
  {
    id: 2,
    title: 'Welcome To Nuwarisha Mayatima',
    description: 'With Love and Hope, We Bring Smiles to Orphaned Children and People with Disabilities',
    buttonText: 'Donate Now',
    buttonLink: 'contact',
    backgroundImage: 'path/to/slide1.jpg',
  },
  // Add more slides as needed
];

const CarouselComponent = () => {
  const [showModal, setShowModal] = useState(false);

  const handleButtonClick = () => {
    // Set the state to show the modal
    setShowModal(true);
  };

  const handleCloseModal = () => {
    // Close the modal
    setShowModal(false);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    beforeChange: (oldIndex, newIndex) => {
      // Add your beforeChange logic here if needed
    },
    afterChange: (currentIndex) => {
      // Add your afterChange logic here if needed
    },
  };

  return (
    <div className="slider-area">
      <div className="slider-active ">
        <Slider {...sliderSettings}>
          {slidesContent.map((slide) => (
            <div key={slide.id} className="single-slider hero-overly01 slider-height slider-bg2 d-flex align-items-center">
              <div className="container">
                <div className="row justify-content-end">
                  <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-8 col-sm-10">
                    <div className="hero-caption fade-animation" data-animation="fadeInUp">
                      <h1>{slide.title}</h1>
                      <p>{slide.description}</p>
                      <a href={slide.buttonLink} className="button btn hero-btn primary" onClick={handleButtonClick}>
                        {slide.buttonText}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

     
    </div>
  );
};

export default CarouselComponent;
