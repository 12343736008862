import React from "react";
import Staff from "./Staff";

const About = () => {
  return (
    <main>
      <div className="slider-area">
        <div className="slider-height2 slider-bg4 hero-overly d-flex align-items-center">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-5 col-lg-6 col-md-6">
                <div className="hero-caption hero-caption2">
                  <h2 style={{ textAlign: "start" }}>About Us</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container services-area1 section-padding">
        <h3 style={{ textAlign: "start" }}>Nuwarisha Mayatima</h3>
        <p style={{ textAlign: "start" }}>
          At Nuwarisha Mayatima, we believe in the power of compassion and community. We are dedicated to making a difference in the lives of orphans and individuals with disabilities. Our mission is to provide love, care, and support, ensuring that every person, regardless of their circumstances, has the opportunity to lead a fulfilling life.
        </p>
        <div className="row">
          <div className="col-md-6">
            <div className="work-section">
              <h3 style={{ textAlign: "start" }}>Registering</h3>
              <p style={{ textAlign: "start" }}>
                We are dedicated to making a meaningful difference in the lives
                of those who need it most. Our primary focus is on registering
                orphans, the elderly, widows, and individuals with disabilities.
                Through this registration process, we aim to create a bridge of
                support and empowerment for these vulnerable members of our
                community. Our commitment extends beyond just providing aid; we
                strive to nurture a sense of belonging and hope in the hearts of
                those we serve.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="work-section">
              <h3 style={{ textAlign: "start" }}>Provide AIDS</h3>
              <p style={{ textAlign: "start" }}>
                We provide all the necessary aids to orphans, elders, widows,
                and people with disabilities. We believe that everyone,
                regardless of their circumstances, deserves to live a life of
                dignity, comfort, and support. Through our relentless commitment
                and unwavering compassion, we strive to empower and uplift those
                who are most vulnerable in our society.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container services-area1 ">

        <div className="row">
          <div className="col-md-12">
            <h2 style={{ textAlign: "start" }}>Our Team</h2>

            <Staff />

          </div>
        </div>
      </div>
      <hr />


    </main>
  );
};

export default About;