import React from 'react';
import { Link } from 'react-router-dom';
import logo from './nuwarisha_logo.png'

function Header() {
    return (
        <header>
            <div className="header-area">
                <div className="main-header">
                    <div className="header-top d-none d-sm-block">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="d-flex justify-content-between flex-wrap align-items-center">
                                        <div className="header-info-left">
                                            <ul>
                                                <li><i className="fas fa-phone-alt"></i> +255 778 314 679</li>
                                                <li><a href="mailto:info@nuwarisha.org">info@nuwarisha.org</a></li>
                                            </ul>
                                        </div>
                                        <div className="header-info-right">
                                            <ul className="header-social">
                                                <li><Link to="#"><i className="fab fa-facebook"></i></Link></li>
                                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                <li> <a href="#"><i className="fab fa-instagram"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header-bottom header-sticky">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-xl-2 col-lg-2">
                                    <div className="logo">
                                        <Link to="/"><img src={logo} width='60px' height='60px' alt="nuwarisha logo" /></Link>
                                    </div>
                                </div>
                                <div className="col-xl-10 col-lg-10">
                                    <div className="main-menu f-right  d-xl-block">
                                        <nav>
                                            <ul id="navigation">
                                                <li><Link to="/">Home</Link></li>
                                                <li><Link to="/events">Events</Link></li>
                                                <li><Link to="/about">About</Link></li>

                                                <li><Link to="/contact">Contact</Link></li>

                                            </ul>
                                        </nav>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </header >
    );
}

export default Header;
