import React, { useState, useEffect } from 'react'; import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';
import CarouselComponent from './CarouselComponent';
export default function Content() {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        // Fetch events from Laravel API endpoint
        axios.get('https://backend.nuwarisha.org/') // Replace '/api/events' with your actual API endpoint
            .then(response => {
                setEvents(response.data);
            })
            .catch(error => {
                console.error('Error fetching events:', error);
            });
    }, []);

    const carouselSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
        prevArrow: <button className="slick-prev">{'<'}</button>,
        nextArrow: <button className="slick-next">{'>'}</button>,
    };


    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000, // Set the interval for autoplay in milliseconds
        beforeChange: (oldIndex, newIndex) => {
            // Add your beforeChange logic here if needed
        },
        afterChange: (currentIndex) => {
            // Add your afterChange logic here if needed
        },
    };

    const slidesContent = [
        {
            id: 1,
            title: 'Karibu Nuwarisha Mayatima',
            description: 'Kwa Upendo na Matumaini, Tunaweka Tabasamu kwa Watoto Yatima na wasojiweza',
            buttonText: 'Ungana nasi',
            buttonLink: '#',
            backgroundImage: 'path/to/slide1.jpg',
        },

        {
            id: 2,
            title: 'Karibu Nuwarisha Mayatima',
            description: 'Kwa Upendo na Matumaini, Tunaweka Tabasamu kwa Watoto Yatima na wasojiweza',
            buttonText: 'Ungana nasi',
            buttonLink: '#',
            backgroundImage: 'path/to/slide1.jpg',
        },
        // Add more slides as needed
    ];



    return (
        <main>
            <CarouselComponent />
            <div className="events-carousel section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-10 col-sm-11">
                            <div className="section-tittle text-center mb-60">
                                <h2>Our Events</h2>
                            </div>
                        </div>
                    </div>
                    {events.length > 0 ? (
                        <Slider {...carouselSettings}>
                            {events.map(event => (
                                <div key={event.id} className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="event-card">
                                        <img src={event.imageUrl} alt={event.title} />
                                        <h3>{event.title}</h3>
                                        <p>{event.description}</p>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    ) : (
                        <div className="text-center">
                            <p>No events at the moment. Stay tuned for future updates!</p>
                        </div>
                    )}
                </div>
            </div>

            <div className="container services-area1 section-padding">
                <div className="row">
                    <div className="col-md-4">
                        <div className="about-section">
                            <h3 style={{ textAlign: "start" }}>Objective</h3>
                            <p style={{ textAlign: "start" }}>
                                Our Objective is to create a positive and lasting impact on the
                                lives of individuals and communities in need. We are committed
                                to addressing critical social and humanitarian challenges
                                through a range of programs and initiatives that promote
                                well-being, equality, and empowerment.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="about-section">
                            <h3 style={{ textAlign: "start" }}>Mission</h3>
                            <p style={{ textAlign: "start" }}>
                                Our Mission is to provide essential support and resources to
                                vulnerable populations, fostering a sense of hope,
                                self-reliance, and a better quality of life. We aim to be a
                                catalyst for positive change by offering a helping hand to those
                                facing adversity and creating opportunities for growth,
                                development, and sustainability.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="about-section">
                            <h3 style={{ textAlign: "start" }}>Vision</h3>
                            <p style={{ textAlign: "start" }}>
                                Our Vision is to build a world where every person, regardless of
                                their background or circumstances, has the opportunity to thrive
                                and reach their full potential. We aspire to be a driving force
                                in shaping a society where compassion, inclusivity, and social
                                justice are the cornerstones of our collective existence.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="about-section">
                            <h3 style={{ textAlign: "start" }}>Values</h3>
                            <ul style={{ textAlign: "start" }}>
                                <li>
                                    <b>Compassion:</b> We operate with a deep sense of empathy,
                                    understanding, and care for the people and communities we
                                    serve. Compassion is at the core of everything we do.
                                </li>
                                <li>
                                    <b>Empowerment: </b>We believe in empowering individuals to
                                    take charge of their lives, equipping them with the skills,
                                    resources, and opportunities they need to succeed.
                                </li>
                                <li>
                                    <b>Inclusivity: </b>Inclusivity is our commitment to
                                    celebrating diversity and ensuring that no one is left behind.
                                    We value every voice and perspective.
                                </li>
                                <li>
                                    <b>Integrity:</b> We uphold the highest standards of honesty,
                                    transparency, and accountability in all our actions and
                                    interactions.
                                </li>
                                <li>
                                    <b>Collaboration:</b> We recognize that working together with
                                    partners, communities, and stakeholders is key to achieving
                                    our goals and creating lasting change.
                                </li>
                                <li>
                                    <b>Innovation:</b> We continually seek innovative solutions
                                    and approaches to address evolving challenges, ensuring our
                                    efforts remain effective and relevant.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>



        </main>
    );
}
