import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './nuwarisha_logo.png'


export default function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <footer>
            <div className="footer-wrapper">
                <div className="footer-area footer-padding">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-8">
                                <div className="single-footer-caption mb-50">
                                    <div className="single-footer-caption mb-20">
                                        <div className="footer-logo mb-35">
                                            <Link to="/" >
                                                <h4> <img src={Logo} width="70px" height="70px" alt="" />
                                                    <span className="footer-tittle" style={{ color: 'white' }}>Nuwarisha</span></h4>
                                            </Link>
                                        </div>
                                        <div className="footer-tittle">
                                            <div className="footer-pera">
                                                <p>You can also find us on our social media networks. Click the link below.</p>                                            </div>
                                        </div>
                                        <div className="footer-social">
                                            <Link to=""><i className="fab fa-facebook"></i></Link>
                                            <Link to="#"><i className="fab fa-instagram"></i></Link>
                                            <Link to="#"><i className="fab fa-youtube"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="offset-xl-1 col-xl-2 col-lg-2 col-md-4 col-sm-6">
                                <div className="single-footer-caption mb-50">
                                    <div className="footer-tittle">
                                        <h4>Navigation</h4>
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/events">Events</Link></li>
                                            <li><Link to="/about">About</Link></li>
                                            <li><Link to="/contact">Contact</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                                <div className="single-footer-caption mb-50">
                                    <div className="footer-tittle">
                                        <h4>Our Work</h4>
                                        <ul>
                                            <li><Link to="/">Orphans Registration</Link></li>
                                            <li><Link to="/">Elders Registratios</Link></li>
                                            <li><Link to="/">Widows Registration</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-8">
                                <div className="single-footer-caption mb-50">
                                    <div className="footer-tittle mb-10">
                                        <h4>Register for Sponsorship</h4>
                                        <p>Register for sponsorship to contribute or participate with us in providing services to orphans and people with disabilities.</p>
                                    </div>


                                    <div className="footer-form mb-20">
                                        <div id="mc_embed_signup">
                                            <form className="subscribe_form relative mail_part">
                                                <input type="email" name="email" id="newsletter-form-email" placeholder="Enter your email" className="placeholder hide-on-focus" />
                                                <div className="form-icon">
                                                    <button type="submit" name="submit" id="newsletter-submit" className="email_icon newsletter-submit button-contactForm">
                                                        <i className="fas fa-arrow-right"></i>
                                                    </button>
                                                </div>
                                                <div className="mt-10 info"></div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom-area">
                    <div className="container">
                        <div className="footer-border">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="footer-copy-right text-center">
                                        <p>Copyright © {currentYear} All rights reserved </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
