import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function Events() {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        axios.get('https://backend.nuwarisha.org/') 
            .then(response => {
                setEvents(response.data);
            })
            .catch(error => {
                console.error('Error fetching events:', error);
            });
    }, []); 
    return (
        <main>

            <div className="slider-area">
                <div className="slider-height2 slider-bg4 hero-overly d-flex align-items-center">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-5 col-lg-6 col-md-6">
                                <div className="hero-caption hero-caption2">
                                    <h2>Our Events</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="services-area1 section-padding">
                {events.length > 0 ? (
                    // Display events if available
                    <ul>
                        {events.map(event => (
                            <li key={event.id}>
                                <h3>{event.title}</h3>
                                <p>{event.description}</p>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div className="no-events-message">
                        <p>No events at the moment.</p>
                        <i className="fas fa-calendar-alt fa-4x pink-icon"></i>    </div>
                )}
            </div>
        </main>
    );
}
