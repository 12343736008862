import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/header';
import Footer from './components/Footer';
import Content from './components/Content';
import Upper from './components/Upper';
import About from './components/About';
import Events from './components/Events';
import Contact from './components/Contact';


const App = () => {
  return (
    <div className="App">
      <Router>
        <Header />

        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/" element={<Content />} />
          <Route path="/events" element={<Events />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>

        <Footer />
        <Upper />
      </Router>

    </div>
  );
}

export default App;
